<template>
  <dashboard-page-wrapper title="Team">
    <team-menu activeMenu="Hiring Managers"/>
    <b-row>
      <b-col md="12">
        <card bodyClasses="p-0">
          <div v-if="isShownHMList">
            <header class="py-1">
              <div class="d-flex justify-content-between px-4 py-2 align-items-center">
                <div class="d-flex align-items-center">
                  <span class="font-weight-bold mr-3 text-nowrap">{{ seatsData.length }}/{{ totalHiringManagerSeats }} seats filled</span>
                  <el-input v-model="searchHiringManager" @input="handleSearch" class="search-form ml-3" clearable>
                    <template #prefix>
                      <el-tooltip effect="dark" placement="top-start" content="Search tags, email, names, and numbers">
                        <i class="el-input__icon el-icon-search text-dark font-weight-bold"></i>
                      </el-tooltip>
                    </template>
                  </el-input>
                </div>
                <button class="btn btn-primary" @click="showHiringManagerModal">
                  <i class="fas fa-user-plus"></i> Invite
                </button>
              </div>
            </header>
            <!-- Hiring Manager table -->
            <team-hiring-managers-table
              :hiringManagerSeats="filteredHiringManagerSeats"
            ></team-hiring-managers-table>
          </div>
          <div v-else class="text-center py-7 contact-us-msg">
            <span>Your account doesn't have a Hiring Manager plan yet.
            <br> <a @click="openIntercom()" id="contactus_link"> Contact us </a> through the Support tab to get access! </span>
          </div>
        </card>
      </b-col>
    </b-row>
    <!-- Create new team member Modal -->
    <invite-member-modal ref="createNewTeamMemberModal" :isTeamHiringManagers="true"/>
  </dashboard-page-wrapper>
</template>

<script>
import DashboardPageWrapper from '@/components/App/UI/DashboardPageWrapper.vue';
import TeamMenu from './TeamHeaderMenu.vue';
import TeamHiringManagersTable from './TeamHiringManagersTable.vue';
import InviteMemberModal from './InviteMemberModal';
import {mapActions, mapGetters} from 'vuex';
import {FETCH_SEATS_REQUEST} from '@/store/storeActions';
import {seatRoles, seatStatus, seatTypes, supportUrl} from '@/store/userConstants';
import {createHmac} from 'crypto';

export default {
  components: {
    DashboardPageWrapper,
    TeamMenu,
    TeamHiringManagersTable,
    InviteMemberModal,
  },
  data() {
    return {
      searchHiringManager: '',
      filteredHiringManagerSeats: [],
      helpUrl: `${supportUrl}5925486-how-to-manage-your-team-members`,
      seatTypes,
      isShownHMList: false,
    };
  },
  computed: {
    ...mapGetters([
      'totalSeats',
      'availableSeats',
      'usedSeats',
      'accountSeats',
      'accountFreeUsersLoaded',
      'atsIntegration',
      'atsIntegrationEnabled',
      'integrationLoaded',
      'userId',
      'userEmail',
      'featuresEnabled',
    ]),
    seats() {
      return this.accountSeats.filter((seat) => seat.seatRole === seatRoles.HIRING_MANAGER).filter(
        (seat) => seat.status === seatStatus.USED || seat.status === seatStatus.PENDING_INVITATION ||
          seat.status === seatTypes.NON_TEXTER)
          .map((seat) => {
            // Ensure each seat has a tags property (even if it's empty)
            if (!seat.tags) {
              seat.tags = []; // Assign an empty array if tags are missing
            }
            return seat; // Return the modified seat
          });
    },
    seatsData() {
      return this.accountSeats.filter((seat) => seat.seatRole === seatRoles.HIRING_MANAGER).filter(
        (seat) => seat.seatType !== seatTypes.NON_TEXTER && (seat.status === seatStatus.USED ||
          seat.status === seatStatus.PENDING_INVITATION));
    },
    usedEmails() {
      return this.seats.map((seat) => seat.metadata ? seat.metadata.invitedEmail : '');
    },
    totalHiringManagerSeats() {
      return this.accountSeats.filter((seat) => seat.seatRole === seatRoles.HIRING_MANAGER).length;
    },
    usedHiringManagerSeats() {
      return this.accountSeats.filter((seat) => seat.seatRole === seatRoles.HIRING_MANAGER).filter(
        (seat) => seat.status === seatStatus.USED || seat.status === seatStatus.PENDING_INVITATION).length;
    },
    seatsFilled() {
      return this.usedSeats >= this.totalHiringManagerSeats;
    },
  },
  methods: {
    ...mapActions([
      FETCH_SEATS_REQUEST,
    ]),
    showHiringManagerModal() {
      this.$refs.createNewTeamMemberModal.displayToggle(true);
    },
    /**
     * Filters the list of hiring managers based on the user's search input.
     */
    handleSearch() {
      // Convert the search query to lowercase for case-insensitive matching
      const searchQuery = this.searchHiringManager.toLowerCase();

      // Split the search query by spaces or commas to handle multiple tags search
      const searchTerms = searchQuery.split(/[\s,]+/).filter((term) => term);
      // Filter the hiring managers based on the search query
      this.filteredHiringManagerSeats = this.seats.filter((manager) => {
        const fullName = manager.user ? `${(manager.user.firstName || '').toLowerCase()} ${(manager.user.lastName || '').toLowerCase()}` : '';
        const nameMatch = fullName.includes(searchQuery);
        const emailMatch = (manager.user ? manager.user.email : (manager.metadata.invitedEmail || '')).toLowerCase().includes(searchQuery);
        const numberMatch = (manager.twilioNumber || '').toLowerCase().includes(searchQuery);
        // Check if any of the tags match any of the search terms
        const tagsMatch = searchTerms.some((term) => manager.tags.some((tag) => tag.toLowerCase().includes(term)),
        );
        return nameMatch || emailMatch || numberMatch || tagsMatch;
      });
    },
    generateUserHash: function() {
      const hmac = createHmac('sha256', process.env.VUE_APP_INTERCOM_SECRET_KEY);
      hmac.update(this.userId.toString());
      return hmac.digest('hex');
    },
    openIntercom() {
      this.$intercom.boot({
        user_id: this.userId,
        user_hash: this.generateUserHash(),
        email: this.userEmail,
        custom_launcher_selector: '#contactus_link',
      });
      // Add click event listener to close the Intercom when clicked outside
      this.addClickOutsideListener();
    },
    addClickOutsideListener() {
      document.addEventListener('click', this.handleClickOutside);
    },
    // Function to handle click outside the Intercom
    handleClickOutside(event) {
      const intercomLauncher = document.querySelector('#contactus_link');
      if (!intercomLauncher.contains(event.target)) {
        this.closeIntercom();
      }
    },
    // Close Intercom and remove the outside click listener
    closeIntercom() {
      this.$intercom.hide();
      document.removeEventListener('click', this.handleClickOutside); // Remove listener after closing
    },
  },
  watch: {
    searchHiringManager() {
      // Automatically call handleSearch when searchHiringManager changes
      this.handleSearch();
    },
    seats: {
      immediate: true, // Run the watcher immediately when component is created
      handler(newSeats) {
        this.filteredHiringManagerSeats = newSeats; // Update the filteredHiringManagerSeats whenever `seats` changes
      }
    }
  },
  created() {
    !this.accountFreeUsersLoaded && this.FETCH_SEATS_REQUEST();
    // Initialize filteredHiringManagerSeats to all hiring managers when the component is created
    setTimeout(() => {
      this.filteredHiringManagerSeats = this.seats;
    }, 100);
  },
  mounted() {
    if (this.featuresEnabled && this.featuresEnabled.includes('hiring_manager')) {
      this.isShownHMList = true;
    }
  },
};
</script>
<style>
  .contact-us-msg span {
    line-height: 2.0;
  }
</style>
