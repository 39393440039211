<template>
  <div class="turn-on-office-modal">
    <modal
      :show="display"
      v-on:close="displayToggle(false)"
      footerClasses="d-none"
      headerClasses="app-border-b align-items-center"
    >
      <template v-slot:header>
          <h5 class="modal-title" id="exampleModalLabel">{{isTransfer ? 'Transfer hiring manager seat' : 'Get new number'}}</h5>
      </template>
      <ValidationObserver v-slot="{ invalid, validate }" ref="formValidator">
        <b-form @submit.prevent="validate().then(save)">
          <!-- Transfer -->
          <b-row v-if="isTransfer">
            <b-col md="12">
              Texting number, tags, and text message inbox will be transferred to this new team member.
            </b-col>
            <b-col md="12 mt-4">
              <label class="form-control-label">New hiring manager's email</label>
              <base-input
                type="email"
                placeholder="Email Address"
                v-model="email"
                name="Email"
                rules="required|email"
                @keydown="handleModalClosePrevent"
              >
              </base-input>
            </b-col>
          </b-row>

          <!-- Get new number -->
          <b-row v-else>
            <b-col md="12">
              <get-number-screen
                :isTeamHiringManagers="true"
                @phone-selected="setPhoneNumber"
                @area-code-changed="resetPhoneNumber"
              />
            </b-col>
          </b-row>
          <!-- Cancel and save buttons -->
          <div class="d-flex justify-content-end ">
            <button
              class="btn btn-outline-primary border-0"
              @click.prevent="closeModal"
            >
              Cancel
            </button>
            <button v-if="isTransfer" class="btn btn-primary" type="submit" :disabled="invalid">
              <i class="ni ni-send"></i>
              Transfer
            </button>
            <button v-else class="btn btn-primary" type="submit" :disabled="invalid || !phoneNumber">
              Save
            </button>
          </div>
        </b-form>
      </ValidationObserver>
    </modal>
  </div>
</template>

<script>
import GetNumberScreen from '@/components/App/User/GetNumber/GetNumberScreen.vue';

export default {
  components: {
    GetNumberScreen,
  },
  props: {
    isTransfer: {
      type: Boolean,
    },
  },
  data: function() {
    return {
      display: false,
      email: '',
      phoneNumber: null,
    };
  },
  methods: {
    displayToggle: function(isDisplay) {
      this.display = isDisplay;
      isDisplay && (this.email = '');
    },
    save: function() {
      if (this.isTransfer) {
        this.$emit('on-transfer', this.email);
      } else {
        this.$emit('on-get-new-num', this.phoneNumber);
      }
      setTimeout(() => {
        this.displayToggle(false);
      });
    },
    handleModalClosePrevent(event) {
      // Prevent the default behavior for Enter key in modal
      if (event.key === 'Enter') {
        event.preventDefault();
        event.stopImmediatePropagation();
      }
    },
    setPhoneNumber(phone) {
      this.phoneNumber = phone; // Update the phone number when selected
    },
    resetPhoneNumber() {
      this.phoneNumber = null; // Reset phone number
    },
    closeModal() {
      this.phoneNumber = null; // Reset phone value to null
      this.displayToggle(false);
    },
  },
};
</script>
