<template>
  <div>
    <el-table
      ref="teamTable"
      class="table-responsive table-flush mb-4 members-table"
      header-row-class-name="thead-light"
      :data="hiringManagerSeats"
      selectable
      highlight-current-row
      @current-change="handleCurrentChange"
      empty-text="Send an invite to start filling your seats"
      :cell-style="{height: '58px'}"
      :default-sort="{ prop: 'name', order: 'descending' }"
    >
      <!-- Name Column -->
      <el-table-column label="Name" prop="name" sortable :sort-method="sortByName">
        <template v-slot="{ row }">
          <div v-if="row.status === seatStatus.PENDING_INVITATION">
            <b-badge class="invite-sent-badge">
              Invitation Pending
            </b-badge>
          </div>
          <div v-else>
            {{ (row.user) ? row.user.firstName + ' ' + ((row.user.lastName) ? row.user.lastName : '') : '' }}
            {{ (row.user) && (row.user.id) && (userId === row.user.id) ? '(you)' : '' }}
          </div>
        </template>
      </el-table-column>

      <!-- Email Column -->
      <el-table-column label="Email" prop="email" sortable :sort-method="sortByEmail">
        <template v-slot="{ row }">
          <el-tooltip effect="dark" placement="bottom-start"
                      :content="(row.user) ? row.user.email : row.metadata.invitedEmail">
            <span>{{ (row.user) ? row.user.email : row.metadata.invitedEmail }}</span>
          </el-tooltip>
        </template>
      </el-table-column>

      <!-- Keeyora Number Column -->
      <el-table-column label="Number" prop="phoneNumber">
        <template v-slot="{ row }">
          {{
            row.twilioNumberFormatted ? row.twilioNumberFormatted :
              row.seatType === seatTypes.NON_TEXTER ? 'Non texter' : ' - '
          }}
        </template>
      </el-table-column>

      <!-- Tags Column -->
      <el-table-column label="Tags" prop="tags" :width="columnWidths.tags">
        <template v-slot="{ row }">
          <div v-if="isEditTags && currentEditRow === row.id">
            <div class="edit-tags-on" :style="getTagStyles(row)">
              <!-- Editable Tags -->
              <tags-input
                type="text"
                v-model="row.tags"
                class="tags-input"
                ref="tagsInputRef"
              />
              <!-- Add a custom close button to clear all tags -->
              <div v-if="row.tags.length > 0" @click="clearTags(row)" class="tags-close-icon">
                <img :src="closeImg"/>
              </div>
            </div>
            <div class="d-flex justify-content-start hiring-managers-tags-btns mt-2">
              <button class="btn btn-sm btn-primary" @click="saveTags(row.id, row)">
                Save
              </button>
              <button class="btn btn-outline-primary" @click="cancelEditTags(row)">
                Cancel
              </button>
            </div>
          </div>

          <!-- Display Tags Section -->
          <div v-else class="d-flex align-items-end hiring-managers-tags justify-content-between">
            <div class="d-flex flex-wrap align-items-end justify-content-start" :style="getTagStyles(row)">
              <div v-for="(tag, index) in row.tags"
                   :key="index"
                   class="mr-2 tag-row mt-2"
              >
                {{ tag }}
              </div>
            </div>

            <!-- Edit icon -->
            <div class="hiring-managers-tags-edit ml-3" @click="handleEditTags(row.id, row)">
              <i class="fas fa-pencil-alt"> </i>
            </div>
          </div>
        </template>
      </el-table-column>

      <!-- Menu Start -->
      <el-table-column label="Actions" prop="actions">
        <template slot-scope="scope" class="active-element">
          <el-dropdown trigger="click">
            <span>
              <i class="fas fa-ellipsis-h mt-2"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="scope.row.status !== seatStatus.PENDING_INVITATION"
                                @click.native="tryRemoveHiringManager(scope.row)">
                Remove
              </el-dropdown-item>
              <el-dropdown-item v-if="scope.row.status === seatStatus.PENDING_INVITATION"
                                @click.native="resendInvitation(scope.row)">
                Resend Invitation
              </el-dropdown-item>
              <el-dropdown-item v-if="scope.row.status === seatStatus.PENDING_INVITATION"
                                @click.native="cancelInvitation(scope.row)">
                Cancel Invite
              </el-dropdown-item>
              <el-dropdown-item v-if="scope.row.status !== seatStatus.PENDING_INVITATION"
                                @click.native="tryTransferHiringManager(scope.row)">
                Transfer
              </el-dropdown-item>
              <el-dropdown-item v-if="scope.row.status !== seatStatus.PENDING_INVITATION"
                                @click.native="tryGetNewNumberHiringManager(scope.row)">
                Get new number
              </el-dropdown-item>
              <el-dropdown-item v-if="scope.row.status === 'pending'">
                Resend invite
              </el-dropdown-item>
              <el-dropdown-item v-if="scope.row.status === 'pending'">
                Cancel invite
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
      <!-- Menu End -->
    </el-table>

    <!-- Remove Confirmation modal -->
    <confirmation-modal
      ref="removeHMConfirmationModal"
      :header="confirmData.header"
      :question="confirmData.question"
      :confirmBtnText="confirmData.acceptTxt"
      :cancelBtnText="confirmData.rejectTxt"
      confirmBtnType="danger"
      @on-accept="removeHMSeat"
    />

    <confirmation-modal
      ref="cancelInvitationConfirmationModal"
      :header="confirmCancelData.header"
      :question="confirmCancelData.question"
      :confirmBtnText="confirmCancelData.acceptTxt"
      :cancelBtnText="confirmCancelData.rejectTxt"
      confirmBtnType="danger"
      @on-accept="removeHMSeat"
    />

    <!-- Transfer HM Seat modal -->
    <transfer-and-get-new-num-modal
      :isTransfer="true"
      ref="transferHiringManagerModal"
      @on-transfer="transferHiringManagerSeat"
    />
    <!-- Get new Numbers modal -->
    <transfer-and-get-new-num-modal
      ref="getNewNumHiringManagerModal"
      @on-get-new-num="getNewNumber"
    />
  </div>
</template>

<script>
import {Dropdown, DropdownItem, DropdownMenu, Table, TableColumn} from 'element-ui';
import ConfirmationModal from '@/components/App/modals/ConfirmationModal.vue';
import TransferAndGetNewNumModal from './TransferAndGetNewNumModal.vue';
import TagsInput from '@/components/Inputs/TagsInput.vue';
import {seatStatus, seatTypes} from '@/store/userConstants';
import {mapActions, mapGetters} from 'vuex';
import {
  GET_NEW_NUMBER,
  REMOVE_SEAT_REQUEST,
  TRANSFER_HIRING_MANAGER_SEAT,
  UPDATE_SEAT_TAGS,
} from '@/store/storeActions';
import {resendInvitationApi} from '@/api/accountApi';
import appNotification from '@/util/appNotification';

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    ConfirmationModal,
    TransferAndGetNewNumModal,
    TagsInput,
  },
  data() {
    return {
      isEditTags: false,
      currentEditRow: null,
      seatStatus: seatStatus,
      seatTypes: seatTypes,
      closeImg: 'img/tools/close.svg',
      columnWidths: {
        tags: '400px',
      },
      originalTags: null, // Store the original tags before editing
      currentHMSeat: null,
    };
  },
  props: {
    hiringManagerSeats: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters([
      'userId',
      'twilioNumberFormatted',
      'seatType',
      'outOfOfficeLoaded',
      'totalSeats',
      'usedSeats',
      'userType',
    ]),
    confirmData: function() {
      const data = {
        header: '',
        question: '',
        acceptTxt: '',
        rejectTxt: '',
      };
      data.header = 'Remove team member';
      data.question = `<div class="px-1">This team member will be removed permanently and will immediately lose access to their account. You can refill the seat with a different member by sending a new invite.</div>`;
      data.acceptTxt = 'Confirm removal';
      data.rejectTxt = 'Cancel';
      return data;
    },
    confirmCancelData: function() {
      const data = {
        header: '',
        question: '',
        acceptTxt: '',
        rejectTxt: '',
      };
      data.header = 'Cancel invite';
      data.question = 'Are you sure you’d like to cancel this invite?';
      data.acceptTxt = 'Cancel invite';
      data.rejectTxt = 'Back';
      return data;
    },
  },
  methods: {
    ...mapActions([
      UPDATE_SEAT_TAGS,
      REMOVE_SEAT_REQUEST,
      TRANSFER_HIRING_MANAGER_SEAT,
      GET_NEW_NUMBER,
    ]),
    sortByName: function(a, b) {
      const fullNameA = a.user ? a.user.firstName + ' ' + ((a.user.lastName) ? a.user.lastName : '') : '';
      const fullNameB = b.user ? b.user.firstName + ' ' + ((b.user.lastName) ? b.user.lastName : '') : '';
      const nameA = fullNameA ? fullNameA.toUpperCase() : '';
      const nameB = fullNameB ? fullNameB.toUpperCase() : '';
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    },
    sortByEmail: function(a, b) {
      const emailA = a.user ? a.user.email.toUpperCase() : a.metadata.invitedEmail.toUpperCase();
      const emailB = b.user ? b.user.email.toUpperCase() : b.metadata.invitedEmail.toUpperCase();
      if (emailA < emailB) return -1;
      if (emailA > emailB) return 1;
      return 0;
    },
    tryRemoveHiringManager: function() {
      if (this.$refs.removeHMConfirmationModal) {
        this.$refs.removeHMConfirmationModal.openModal();
      }
    },
    cancelInvitation: function() {
      if (this.$refs.cancelInvitationConfirmationModal) {
        this.$refs.cancelInvitationConfirmationModal.openModal();
      }
    },
    tryTransferHiringManager: function() {
      this.$refs.transferHiringManagerModal.displayToggle(true);
    },
    tryGetNewNumberHiringManager: function() {
      this.$refs.getNewNumHiringManagerModal.displayToggle(true);
    },
    handleEditTags: function(id, row) {
      this.isEditTags = true;
      this.currentEditRow = id;
      this.originalTags = [...row.tags]; // Create a copy of the original tags

      // Focus on the tags-input after the DOM updates
      this.$nextTick(() => {
        const tagsInputComponent = this.$refs.tagsInputRef;
        if (tagsInputComponent && tagsInputComponent.focusInput) {
          tagsInputComponent.focusInput();
        }
      });
    },
    clearTags(row) {
      row.tags = [];
    },
    // Handle saving the tags for a specific row
    saveTags(seatId, row) {
      this.isEditTags = false; // Hide the input after saving
      this.currentEditRow = null; // Reset the current edit row
      const tags = row.tags.map(tag => tag.trim()); // Trim whitespace from each tag
      const payload = {
        tags: tags,
      };
      this.UPDATE_SEAT_TAGS({seatId, payload});
    },
    // Cancel editing and reset
    cancelEditTags(row) {
      if (this.originalTags !== null) {
        // Restore the original tags to prevent clearing
        row.tags = this.originalTags;
      }
      this.isEditTags = false;
      this.currentEditRow = null;
    },
    handleCurrentChange: function(val) {
      this.currentHMSeat = val;
    },
    removeHMSeat: function() {
      this.REMOVE_SEAT_REQUEST({
        payload: {
          userToRemove: this.currentHMSeat.userId, email: this.currentHMSeat.metadata ?
            this.currentHMSeat.metadata.invitedEmail : null,
        },
        status: this.currentHMSeat.status,
      });
    },
    async resendInvitation(seat) {
      const email = !!seat.metadata && seat.metadata.invitedEmail;
      try {
        const res = await resendInvitationApi({email});
        res.status === 'ok' && appNotification.notify({
          title: 'Invite sent!',
          message: 'Ask your team member to check their email',
        });
      } catch { }
    },
    transferHiringManagerSeat: function(email) {
      const seatId = this.currentHMSeat.id;
      const payload = {
        email: email,
      };
      this.TRANSFER_HIRING_MANAGER_SEAT({seatId, payload});
    },
    getNewNumber: function(phone) {
      const seatId = this.currentHMSeat.id;
      const payload = {
        phoneNumber: phone,
      };
      this.GET_NEW_NUMBER({seatId, payload});
    },
    // Adjust the scroll based on the height of the tags container
    getTagStyles(row) {
      const maxLines = 2; // Max lines before the tags become scrollable
      const hasTags = row.tags.length > 0;
      const shouldScroll = row.tags.length > maxLines;
      // Conditional styles for when scrolling is not needed
      return {
        height: hasTags && shouldScroll ? '88px' : 'auto',
        overflowY: hasTags && shouldScroll ? 'auto' : 'visible',
      };
    },
  },
};
</script>

<style lang="scss">
div.el-table .el-table__row:hover {
  background: #f5f5f5 !important;
}

.members-table {
  .el-table__row {
    cursor: pointer;
  }
}

div.el-table .cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.invite-sent-badge {
  text-transform: none;
  background-image: linear-gradient(to right, #EA337F, #901CDD) !important;
  color: #f5f5f5;
  padding: 0.4rem;
}
</style>
